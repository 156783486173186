import React, { useState } from 'react'
import { CityData } from '../../data/travelData'
import { Marker } from 'react-map-gl'
import { Box, Icon } from '@chakra-ui/react'
import { FiMapPin } from 'react-icons/fi'

interface LocationMarkerProps {
    city: CityData
}

const LocationMarker = ({ city }: LocationMarkerProps) => {
    const [isVisible, setIsVisible] = useState(false)
    const [position, setPosition] = useState<{ x: number; y: number }>({
        x: 0,
        y: 0,
    })

    const handleMouseEnter = () => {
        setIsVisible(true)
    }

    const handleMouseLeave = () => {
        setIsVisible(false)
    }

    const handleMouseMove = (e: React.MouseEvent) => {
        setPosition({ x: e.clientX, y: e.clientY })
    }

    return (
        <>
            <Marker
                longitude={city.longitude}
                latitude={city.latitude}
                anchor="bottom"
            >
                <Icon
                    as={FiMapPin}
                    color={'#7a7a7a'}
                    boxSize={4}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={handleMouseMove}
                    _hover={{ cursor: 'crosshair' }}
                />
            </Marker>
            {isVisible && (
                <Box
                    position="fixed"
                    left={position.x + 10 + 'px'}
                    top={position.y + 10 + 'px'}
                    bgColor="white"
                    color="gray.800"
                    px={2}
                    py={1}
                    borderRadius="md"
                    boxShadow="sm"
                    zIndex={10}
                >
                    {`${city.city}, ${city.country}`}
                </Box>
            )}
        </>
    )
}

export default LocationMarker
