import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    fonts: {
        heading: `'Against'`,
        body: `'Lexend Deca Variable', sans-serif`,
    },
})

export default theme
