import React from 'react'
import { HStack, Link } from '@chakra-ui/react'
import { FiInstagram, FiLinkedin, FiMail } from 'react-icons/fi'

interface SocialsProps {
    color: string
    size?: string
    [key: string]: any
}

const Socials = ({ color, size, ...props }: SocialsProps) => {
    const socialsData = [
        {
            aria: 'Email',
            icon: <FiMail />,
            href: 'mailto:jason@jasonfyw.com',
        },
        {
            aria: 'Linkedin',
            icon: <FiLinkedin />,
            href: 'https://linkedin.com/in/jasonfyw/',
        },
        {
            aria: 'Instagram',
            icon: <FiInstagram />,
            href: 'https://www.instagram.com/jfw.jpg/',
        },
    ]
    return (
        <HStack spacing={2} {...props}>
            {socialsData.map((social, i) => (
                <Link
                    key={i}
                    aria-label={social.aria}
                    href={social.href}
                    fontSize={size ? size : '1.5rem'}
                    color={color}
                    isExternal
                    borderRadius={'full'}
                    transition={'0.2s'}
                    p={2}
                    _hover={{
                        bg: 'gray.50',
                    }}
                >
                    {social.icon}
                </Link>
            ))}
        </HStack>
    )
}

export default Socials
