import React from 'react'
import { Photo } from '../../models/Photo'
import ThumbnailView from './ThumbnailView'
import SinglePhotoView from './SinglePhotoView'
import { useStore } from '../../store'

interface ImageViewerProps {
    photoData: Photo[]
}

const ImageViewer = ({ photoData }: ImageViewerProps) => {
    const currentPhoto = useStore((state) => state.currentPhoto)
    const setCurrentPhoto = useStore((state) => state.setCurrentPhoto)

    const setPrevPhoto = () => {
        const currentIndex = photoData.findIndex(
            (photo) => photo.id === currentPhoto?.id,
        )
        if (currentIndex === 0) {
            setCurrentPhoto(photoData[photoData.length - 1])
        } else {
            setCurrentPhoto(photoData[currentIndex - 1])
        }
    }

    const setNextPhoto = () => {
        const currentIndex = photoData.findIndex(
            (photo) => photo.id === currentPhoto?.id,
        )
        if (currentIndex === photoData.length - 1) {
            setCurrentPhoto(photoData[0])
        } else {
            setCurrentPhoto(photoData[currentIndex + 1])
        }
    }

    return (
        <>
            {currentPhoto === null ? (
                <ThumbnailView
                    photoData={photoData}
                    setCurrentPhoto={setCurrentPhoto}
                />
            ) : (
                <SinglePhotoView
                    photo={currentPhoto}
                    setCurrentPhoto={setCurrentPhoto}
                    setPrevPhoto={setPrevPhoto}
                    setNextPhoto={setNextPhoto}
                />
            )}
        </>
    )
}

export default ImageViewer
