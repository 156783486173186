import React from 'react'
import { Box, GridItem } from '@chakra-ui/react'
import { productPhotoData } from '../../../data/photoData'
import PhotoViewer from '../../../components/PhotoViewer'

const ProductPortfolio = () => {
    return (
        <GridItem area={'main'}>
            <Box py={[5, 10]} textAlign={'center'} w={'100%'} h={'100vh'}>
                <PhotoViewer photoData={productPhotoData} />
            </Box>
        </GridItem>
    )
}

export default ProductPortfolio
