import React from 'react'
import Map from '../../components/Map'
import { GridItem } from '@chakra-ui/react'

const Travel = () => {
    return (
        <GridItem area={'main'}>
            <Map />
        </GridItem>
    )
}

export default Travel
