import React from 'react'
import { Box, GridItem } from '@chakra-ui/react'
import { portraitPhotoData } from '../../../data/photoData'
import PhotoViewer from '../../../components/PhotoViewer'

const PortraitPortfolio = () => {
    return (
        <GridItem area={'main'}>
            <Box py={[5, 10]} textAlign={'center'} w={'100%'} h={'100vh'}>
                <PhotoViewer photoData={portraitPhotoData} />
            </Box>
        </GridItem>
    )
}

export default PortraitPortfolio
