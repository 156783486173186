import React from 'react'
import { Box, GridItem } from '@chakra-ui/react'
import PhotoViewer from '../../components/PhotoViewer'
import { homePhotoData } from '../../data/photoData'

const Home = () => {
    return (
        <GridItem area={'main'}>
            <Box py={[5, 10]} textAlign={'center'} w={'100%'} h={'100vh'}>
                <PhotoViewer photoData={homePhotoData} />
            </Box>
        </GridItem>
    )
}

export default Home
