import { GridItem } from '@chakra-ui/react'
import React, { useEffect } from 'react'

const EcwidStore: React.FC = () => {
    useEffect(() => {
        // Check if the script is already loaded to prevent double-loading
        if (!(window as any)['xProductBrowser']) {
            const script = document.createElement('script')
            script.src =
                'https://app.ecwid.com/script.js?93798532&data_platform=code&data_date=2023-10-19'
            script.async = true
            script.dataset.cfasync = 'false'
            script.onload = () => {
                // Type assertion here
                const xProductBrowser = (window as any)['xProductBrowser']
                if (xProductBrowser) {
                    xProductBrowser(
                        'categoriesPerRow=3',
                        'views=grid(20,3) list(60) table(60)',
                        'categoryView=grid',
                        'searchView=list',
                        'id=my-store-93798532',
                    )
                }
            }

            document.body.appendChild(script)

            // Clean up on component unmount
            return () => {
                document.body.removeChild(script)
            }
        }
    }, [])

    return (
        <GridItem area={'main'} p={8}>
            <div id="my-store-93798532"></div>
        </GridItem>
    )
}

export default EcwidStore
