import { create } from 'zustand'
import { Photo } from './models/Photo'

type State = {
    currentPhoto: Photo | null
    setCurrentPhoto: (photo: Photo | null) => void
}

export const useStore = create<State>((set) => ({
    currentPhoto: null,
    setCurrentPhoto: (photo) => set(() => ({ currentPhoto: photo })),
}))
