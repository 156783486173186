import React, { useState } from 'react'
import { Photo } from '../../models/Photo'
import { AspectRatio, Image, Skeleton, Wrap, WrapItem } from '@chakra-ui/react'

interface ThumbnailViewProps {
    photoData: Photo[]
    setCurrentPhoto: (photo: Photo) => void
}

const GridPhoto = ({
    photo,
    setCurrentPhoto,
}: {
    photo: Photo
    setCurrentPhoto: (photo: Photo) => void
}) => {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false)

    return (
        <Skeleton
            isLoaded={hasLoaded}
            boxSize="100%"
            fadeDuration={1}
            startColor="gray.300"
            endColor="gray.200"
        >
            <AspectRatio key={photo.id} ratio={1 / 1} w={'20rem'}>
                <Image
                    src={photo.src}
                    aria-label={photo.title}
                    objectFit={'cover'}
                    onLoad={() => setHasLoaded(true)}
                    display={hasLoaded ? 'block' : 'none'}
                    onClick={() => setCurrentPhoto(photo)}
                    transition={'0.3s'}
                    _hover={{
                        cursor: 'pointer',
                        opacity: 0.925,
                    }}
                />
            </AspectRatio>
        </Skeleton>
    )
}

const ThumbnailView = ({ photoData, setCurrentPhoto }: ThumbnailViewProps) => {
    return (
        <Wrap spacing={6} justify={'center'} pb={10} pr={[0, 4]}>
            {photoData.map((photo: Photo, i) => (
                <WrapItem key={i}>
                    <GridPhoto
                        photo={photo}
                        setCurrentPhoto={setCurrentPhoto}
                    />
                </WrapItem>
            ))}
        </Wrap>
    )
}

export default ThumbnailView
