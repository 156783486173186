import { v4 as uuidv4 } from 'uuid'

export class Photo {
    id: string
    title: string
    src: string 

    constructor(title: string, src: string) {
        this.id = uuidv4()
        this.title = title
        this.src = src
    }
}
