type Links = Record<string, string | Record<string, string>>

export const linkData: Links = {
    Home: '',
    Portfolio: {
        Sports: 'sports',
        Motorsports: 'motorsports',
        Urban: 'urban',
        Landscape: 'landscape',
        Product: 'product',
        People: 'portrait',
    },
    About: 'about',
    Contact: 'contact',
    Travel: 'travel',
}
