import { Center, GridItem, Heading, VStack, Text } from '@chakra-ui/react'
import React from 'react'

const NotFound = () => {
    return (
        <GridItem area={'main'}>
            <Center px={6}>
                <VStack h={'100vh'} py={[4, 16]} gap={4}>
                    <Heading fontSize={'1.5rem'}>404 - Page Not Found</Heading>
                    <Text>
                        Sorry, the page you are looking for does not exist.
                    </Text>
                </VStack>
            </Center>
        </GridItem>
    )
}

export default NotFound
