import {
    Center,
    GridItem,
    Stack,
    Image,
    VStack,
    Heading,
    Text,
    Box,
    Link,
    Skeleton,
} from '@chakra-ui/react'
import React, { useState } from 'react'

import urban15 from '../../media/urban/urban-15.jpg'

const SocialItem = ({
    name,
    href,
    display,
}: {
    name: string
    href: string
    display: string
}) => (
    <Box>
        <Text fontSize={'0.8rem'} fontWeight={800} textTransform={'uppercase'}>
            {name}
        </Text>
        <Link
            href={href}
            color={'gray.600'}
            transition={'0.25s'}
            borderBottom={'2px solid'}
            borderBottomColor={'gray.600'}
            _hover={{
                color: 'gray.500',
                borderBottomColor: 'gray.500',
            }}
            isExternal
        >
            {display}
        </Link>
    </Box>
)

const Contact = () => {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false)

    return (
        <GridItem area={'main'}>
            <Stack direction={['column', 'row']} h={'100vh'} py={[4, 16]}>
                <Center w={['100%', '50%']} px={6}>
                    <Skeleton
                        isLoaded={hasLoaded}
                        h={['20rem', '100%']}
                        fadeDuration={1}
                        startColor="gray.300"
                        endColor="gray.200"
                    >
                        <Image
                            src={urban15}
                            h={['20rem', '100%']}
                            objectFit={'cover'}
                            onLoad={() => setHasLoaded(true)}
                        />
                    </Skeleton>
                </Center>
                <VStack
                    spacing={6}
                    textAlign={'center'}
                    px={6}
                    pt={[4, 0]}
                    pb={[16, 0]}
                    w={['100%', '36rem']}
                    lineHeight={1.75}
                >
                    <Heading fontSize={'1.5rem'}>CONTACT</Heading>
                    <Text>
                        Feel free to visit my socials or contact me through
                        email if you have any questions or inquiries!
                    </Text>
                    <VStack spacing={4}>
                        <SocialItem
                            name={'Instagram'}
                            href={'https://www.instagram.com/jfw.jpg/'}
                            display={'@jfw.jpg'}
                        />
                        <SocialItem
                            name={'LinkedIn'}
                            href={'https://www.linkedin.com/in/jasonfyw/'}
                            display={'in/jasonfyw'}
                        />
                        <SocialItem
                            name={'Email'}
                            href={'mailto:jason@jasonfyw.com'}
                            display={'jason@jasonfyw.com'}
                        />
                    </VStack>
                </VStack>
            </Stack>
        </GridItem>
    )
}

export default Contact
