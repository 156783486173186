import { Global } from '@emotion/react'

const Fonts = () => (
    <Global
        styles={`
            /* latin */
            @font-face {
                font-family: 'Against';
                font-style: normal;
                src: url('./fonts/against.otf');
            }
      `}
    />
)

export default Fonts
