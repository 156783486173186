import { Box, GridItem } from '@chakra-ui/react'
import React from 'react'
import PhotoViewer from '../../../components/PhotoViewer'
import { sportsPhotoData } from '../../../data/photoData'

const SportsPortfolio = () => {
    return (
        <GridItem area={'main'}>
            <Box py={[5, 10]} textAlign={'center'} w={'100%'} h={'100vh'}>
                <PhotoViewer photoData={sportsPhotoData} />
            </Box>
        </GridItem>
    )
}

export default SportsPortfolio
