import { ReactNode, FC, useEffect, useRef } from 'react'
import {
    Box,
    Flex,
    VStack,
    useDisclosure,
    useColorModeValue,
    Stack,
    Text,
    GridItem,
    Heading,
    Collapse,
    Spacer,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Spin as Hamburger } from 'hamburger-react'
import { linkData } from './LinkData'
import Socials from '../Socials'
import { useStore } from '../../store'

const RawLink = ({
    children,
    url,
    onClose,
    resetCurrentPhoto = true,
}: {
    children: ReactNode
    url: string
    onClose: () => void
    resetCurrentPhoto?: boolean
}) => {
    const setCurrentPhoto = useStore((state) => state.setCurrentPhoto)

    return (
        <a
            href={url}
            onClick={() => {
                onClose()
                resetCurrentPhoto && setCurrentPhoto(null)
            }}
        >
            <Box
                px={2}
                py={1}
                rounded={'md'}
                transition={'0.25s'}
                _hover={{
                    textDecoration: 'none',
                    bg: useColorModeValue('gray.50', 'gray.700'),
                    cursor: 'pointer',
                }}
            >
                {children}
            </Box>
        </a>
    )
}

const NavLink = ({
    children,
    to,
    onClose,
    resetCurrentPhoto = true,
}: {
    children: ReactNode
    to: string
    onClose: () => void
    resetCurrentPhoto?: boolean
}) => {
    const setCurrentPhoto = useStore((state) => state.setCurrentPhoto)

    return (
        <Link
            to={to}
            onClick={() => {
                onClose()
                document.title = 'Jason Wang'
                resetCurrentPhoto && setCurrentPhoto(null)
            }}
        >
            <Box
                px={2}
                py={1}
                rounded={'md'}
                transition={'0.25s'}
                _hover={{
                    textDecoration: 'none',
                    bg: useColorModeValue('gray.50', 'gray.700'),
                    cursor: 'pointer',
                }}
            >
                {children}
            </Box>
        </Link>
    )
}

const NavMenu = ({
    children,
    name,
}: {
    children: ReactNode[]
    name: string
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box
            rounded={'md'}
            transition={'0.25s'}
            _hover={{
                textDecoration: 'none',
                bg: useColorModeValue('gray.50', 'gray.700'),
                cursor: 'pointer',
            }}
        >
            <Flex
                onClick={isOpen ? onClose : onOpen}
                px={2}
                py={1}
                justifyContent={'space-between'}
                _hover={{
                    textDecoration: 'none',
                }}
            >
                <Text>{name}</Text>
            </Flex>
            <Collapse in={isOpen} animateOpacity>
                <Stack spacing={0.5} mt={1} pl={4}>
                    {children}
                </Stack>
            </Collapse>
        </Box>
    )
}

const Nav: FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const setCurrentPhoto = useStore((state) => state.setCurrentPhoto)

    const ref = useRef<any>(null)

    // lifecycle hook to close mobile navbar when there is a mousedown event
    // outside of the navbar
    useEffect(() => {
        function handleClickOutside(event: { target: any }) {
            if (ref.current && !ref.current.contains(event.target)) onClose()
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [onClose, ref])

    const navLinks = Object.entries(linkData).map(([k, v], i) =>
        typeof v === 'string' ? (
            <NavLink key={i} to={v} onClose={onClose}>
                {k}
            </NavLink>
        ) : (
            <NavMenu name={k} key={i}>
                {Object.entries(v).map(([sk, sv], j) => (
                    <Box _hover={{ color: 'gray.600' }} key={j}>
                        <NavLink to={sv} onClose={onClose}>
                            {sk}
                        </NavLink>
                    </Box>
                ))}
            </NavMenu>
        ),
    )
    // Create shop link as a standard <a> tag to force reload
    navLinks.push(
        <RawLink key={navLinks.length} url={'/shop'} onClose={onClose}>
            Shop
        </RawLink>,
    )

    const mainWebsiteLink = (
        <NavLink
            to={'https://jasonfyw.com'}
            onClose={onClose}
            resetCurrentPhoto={false}
        >
            Main website{' '}
            <ExternalLinkIcon fontSize={'0.85rem'} mb={'0.2rem'} ml={1} />
        </NavLink>
    )

    return (
        <GridItem area={'nav'}>
            <Box
                bg={'white'}
                px={[4, 12]}
                pt={[0, 6]}
                position={'fixed'}
                top={0}
                w={['100vw', '16rem']}
                h={{ md: '100vh' }}
                zIndex={100}
                ref={ref}
                alignItems={{ base: 'center', sm: 'left' }}
            >
                <Flex h={[16, '100%']} direction={['row', 'column']}>
                    <Flex alignItems={{ base: 'center', sm: 'left' }}>
                        <Box display={{ md: 'none' }}>
                            <Hamburger
                                toggled={isOpen}
                                toggle={isOpen ? onClose : onOpen}
                                size={16}
                                hideOutline={false}
                            />
                        </Box>
                    </Flex>
                    <Flex alignItems={{ base: 'center', sm: 'left' }} my={4}>
                        <Box
                            transition={'0.5s'}
                            ml={[4, 0]}
                            pl={[0, 2]}
                            _hover={{
                                color: 'gray.600',
                            }}
                        >
                            <Link
                                to={''}
                                onClick={() => {
                                    onClose()
                                    setCurrentPhoto(null)
                                }}
                            >
                                <Heading fontSize={['1rem', '2rem']}>
                                    jason wang
                                </Heading>
                                {/* <Heading fontSize={['0.5rem', '0.8rem']} fontWeight={200} letterSpacing={1}>photography</Heading> */}
                            </Link>
                        </Box>
                    </Flex>

                    {/* Desktop nav sidebar */}
                    <VStack
                        as={'nav'}
                        spacing={6}
                        mt={2}
                        display={{ base: 'none', md: 'flex' }}
                        alignItems={'left'}
                    >
                        {navLinks}
                    </VStack>

                    <Spacer />

                    <Flex direction={'column'} alignItems={'left'}>
                        <Box display={{ base: 'none', md: 'flex' }}>
                            {mainWebsiteLink}
                        </Box>
                        <Box
                            pb={10}
                            mt={4}
                            display={{ base: 'none', md: 'flex' }}
                        >
                            <Socials color={'gray.800'} size={'1.2rem'} />
                        </Box>
                    </Flex>
                </Flex>

                {/* Mobile navbar dropdown */}
                <Collapse in={isOpen} animateOpacity>
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {navLinks}
                            {mainWebsiteLink}
                            <Socials color={'gray.800'} size={'1rem'} />
                        </Stack>
                    </Box>
                </Collapse>
            </Box>
        </GridItem>
    )
}

export default Nav
