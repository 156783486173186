import { Box } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import {
    Layer,
    Map as MapGl,
    NavigationControl,
    Source,
    FillLayer,
} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import './Map.css'
import { travelData } from '../../data/travelData'
import LocationMarker from './LocationMarker'
import mapboxgl from 'mapbox-gl'
// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const countriesLayer: FillLayer = {
    id: 'undisputed area boundary fill',
    type: 'fill',
    'source-layer': 'country_boundaries',
    paint: {
        'fill-outline-color': 'rgba(161, 247, 246, 0.9)',
        'fill-color': 'hsla(179, 38%, 74%, 0.4)',
    },
    filter: [
        'all',
        ['==', ['get', 'disputed'], 'false'],
        [
            'any',
            ['==', 'all', ['get', 'worldview']],
            ['in', 'US', ['get', 'worldview']],
        ],
        ['in', ['get', 'name_en'], travelData.countriesTravelled.join(' ')],
    ],
}

const Map = () => {
    const [viewState, setViewState] = useState({
        longitude: 0,
        latitude: 40,
        zoom: 2,
    })

    const pins = useMemo(
        () =>
            travelData.citiesTravelled.map((city, index) => (
                <LocationMarker key={index} city={city} />
            )),
        [],
    )

    const token =
        process.env.NODE_ENV === 'production'
            ? 'pk.eyJ1IjoiamFzb25meXciLCJhIjoiY2xsbHFuYnR5MTk4MjNybHd6ZzVvNGI5aiJ9.WCltX1A8wzgO4omxG0OVwQ'
            : process.env.REACT_APP_MAPBOX_TOKEN

    return (
        <Box h={'100%'} overflowY={'hidden'}>
            <MapGl
                {...viewState}
                style={{ height: '100%' }}
                onMove={(evt) => setViewState(evt.viewState)}
                mapboxAccessToken={token}
                mapStyle="mapbox://styles/jasonfyw/clllruvcf02iv01qn4m1c1b6b"
            >
                <NavigationControl position="bottom-right" />

                {pins}

                <Source
                    id="country-boundaries"
                    type="vector"
                    url="mapbox://mapbox.country-boundaries-v1"
                >
                    <Layer beforeId="waterway-label" {...countriesLayer} />
                </Source>
            </MapGl>
        </Box>
    )
}

export default Map
