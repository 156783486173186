import data from './travelLocationCoordinates.json'

export type CityData = {
    city: string
    country: string
    latitude: number
    longitude: number
}

export const travelData = {
    countriesTravelled: Object.keys(data),
    citiesTravelled: Object.entries(data).reduce(
        (acc: CityData[], [country, cities]) => [
            ...acc,
            ...Object.entries(cities).map(([city, coord]) => ({
                city: city,
                country: country,
                latitude: coord.latitude,
                longitude: coord.longitude,
            })),
        ],
        [],
    ),
}
